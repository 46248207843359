const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */
    siteId: '609e73115ca404a318260657',
    authMode: ['providers', 'local'],
    authOAuthProviders: [
      [
        'WSSOKeycloak',
        {
          realm: 'Cagip',
          clientId: 'vi-cagip',
          buttonText: 'Accès CA-GIP',
        },
      ],
    ],
    authLocalOptions: {
      mode: 'otp',
    },
    disabledComponents: ['wCardsCommentsLabels'],
    enableSplashScreen: 'wavy',
    showCardDescription: true,
    enableExperimentalBlocks: true,
    metadatas: {
      name: `Radio CA-GIP`,
      colorPrimary: '#009597',
      supportEmailAddress: 'support@radio-ca-gip.fr',
    },
    nuxt: {
      head: {
        title: "Les podcasts de CA-GIP",
        meta: [
          {
            charset: 'utf-8',
          },
          {
            name: 'viewport',
            content:
              'width=device-width, initial-scale=1, minimal-ui, shrink-to-fit=no',
          },
          {
            hid: 'description',
            name: 'description',
            content: `Des podcasts thématiques, à écouter et réécouter sans limite, pour tout savoir sur l'actualité de CA-GIP`,
          },
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            charset: 'utf-8',
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            hid: 'robots',
            name: 'robots',
            content: 'noindex,nofollow',
          },
          {
            hid: 'ogSiteName',
            name: 'og:site_name',
            content: "Radio CA-GIP",
          },
          {
            hid: 'ogTitle',
            name: 'og:title',
            content: 'Les podcasts de CA-GIP',
          },
          {
            hid: 'ogDescription',
            name: 'og:description',
            content: `Des podcasts thématiques, à écouter et réécouter sans limite, pour tout savoir sur l'actualité de CA-GIP`,
          },
          {
            hid: 'ogType',
            name: 'og:type',
            content: 'website',
          },
        ],
      },
    },
    hasAutoplayContent: true,
    hasSuggestedContent: true,
    analytics: {
      matomo: '15',
    },
    useShell: [
      'bb-default',
      {
        mode: 'universes',
        universes: [
          {
            type: 'tag',
            tagId: '61c4657c132f3f00fd5afa4b',
            i18n: {
              fr: {
                title: 'Stratégie CA-GIP',
                description: `Vos podcasts dédiés à la stratégie de CA-GIP`,
              },
            },
            heading: {
              color: '#00003E',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '6512ae7a213a2f792e8cc4bf',
            i18n: {
              fr: {
                title: 'Actualités Tech',
                description: `Les actualités tech de CA-GIP et de son environnement`,
              },
            },
            heading: {
              color: '#06c597',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '62b1922dd7e90c7c1d0ab210',
            i18n: {
              fr: {
                title: 'RSE le podcast',
                description: `Ensemble pour un numérique responsable`,
              },
            },
            heading: {
              color: '#99cc00',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '61c465871fb34f486272dc5a',
            i18n: {
              fr: {
                title: 'Veille Cyber',
                description: `L’essentiel de la veille cyber en 5’ proposé par les équipes Risques IT CA-GIP et Veille Cyber`,
              },
            },
            heading: {
              color: '#65247D',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '63036f2f3dd216f18b5f2bef',
            i18n: {
              fr: {
                title: '3 minutes avec',
                description: `À la rencontre des équipes de CA-GIP, de leurs métiers et de leurs projets`,
              },
            },
            heading: {
              color: '#009597',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '6720b29b0a9341a7ef0a2211',
            i18n: {
              fr: {
                title: 'Paroles de clients',
                description: `Histoires applicatives et retours d'expériences sur l’adoption des plateformes CA-GIP`,
              },
            },
            heading: {
              color: '#006A4E',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '62626a874a7fb87d6826627b',
            i18n: {
              fr: {
                title: 'In&Out',
                description: `Accompagner les experts et non experts dans la compréhension des transformations en cours`,
              },
            },
            heading: {
              color: '#006A4E',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
        ],
        additionalRoutes: [
          {
            name: 'custom-faq',
            component: '~/excentrics/pages/custom-faq.vue',
            path: '/faq',
            chunkName: 'pages/faq',
          },
        ],
      },
    ],
    drawerNavigation: () => [
      {
        type: 'method',
        icon: 'mdi-chat-processing',
        title: 'Notification SMS',
        onClick: (context) => {
          context.formModal = true
        },
      },
      {
        type: 'link',
        icon: 'mdi-frequently-asked-questions',
        value: '/faq',
        title: 'FAQ',
      },
      {
        type: 'link',
        icon: 'mdi-cog-outline',
        value: '/settings',
        title: (ctx) => ctx.$t('glob_sidebar_settings'),
      },
      {
        icon: 'mdi-information-outline',
        value: 'info',
        title: (ctx) => ctx.$t('glob_sidebar_moreabout'),
        noAction: true,
        disableAutoClose: true,
        items: [
          {
            type: 'link',
            title: (ctx) => ctx.$t(`glob_sidebar_moreabout_legals`),
            value: '/data/legal-notice',
          },
          {
            type: 'link',
            title: (ctx) => ctx.$t(`glob_sidebar_moreabout_terms`),
            value: '/data/terms-of-service',
          },
          {
            type: 'link',
            title: (ctx) => ctx.$t(`glob_sidebar_moreabout_data`),
            value: '/data/personal-data',
          },
          {
            type: 'method',
            title: (ctx) => ctx.$t(`glob_sidebar_moreabout_versions`),
            value: 'versions',
            onClick: (context) => {
              context.dialogVersions = true
            },
          },
        ],
      },
      {
        type: 'link',
        icon: 'mdi-help-circle-outline',
        title: (ctx) => ctx.$t(`glob_sidebar_support`),
        value: '/support',
      },
      {
        condition(context) {
          return context.$spoke.site.$data('optAuthentication') === true
        },
        type: 'method',
        icon: 'mdi-exit-to-app',
        value: 'logout',
        title: (ctx) => ctx.$t('glob_sidebar_exit'),
        onClick: async (context) => {
          await context.$store.dispatch('auth/logout')
        },
      },
    ],
    WFeedBack: {
      // ↓↓↓ recorder name / ID (for multiple recorder) ↓↓↓
      firstFeedback: {
        title: 'Inscription aux notifications SMS',
        name: 'Inscription SMS',
        type: 'form',
        headCaption: `Inscrivez-vous pour recevoir les notifications SMS et suivre les actualités de Radio CA-GIP ! 🎧`,
        subCaption: `Je suis informé que Radio CA-GIP traite les données recueillies pour permettre l'envoi de SMS. Pour en savoir plus, reportez-vous à notre <a href="/data/personal-data">Politique de données personnelles.</a>`,
        formSentMessage: `Votre inscription a bien été enregistrée.`,
        pseudonym: {
          label: 'Nom',
          placeholder: 'Dupont',
        },
        recorderForm: [
          {
            label: 'Prénom',
            placeholder: 'Michel',
            value: '',
            type: 'text-field',
            required: true,
          },
          {
            label: `Je m'inscris avec...`,
            items: ['Mon n° professionnel', 'Mon n° personnel'],
            value: '',
            type: 'select',
            required: true,
          },
          {
            label: 'N° de téléphone',
            placeholder: '01 23 45 67 89',
            value: '',
            type: 'phone-number',
            required: true,
          },
          {
            label: 'Je consens à recevoir des notifications par SMS',
            value: false,
            type: 'checkbox',
            required: true,
          },
        ],
      },
    },
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
